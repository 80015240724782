import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../css/SignInForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const signIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Вы вошли в систему!"); // Translated
      navigate('/dashboard'); // Navigate after successful sign-in
    } catch (error) {
      console.error(error.message);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log("Вы вошли в систему через Google!"); // Translated
      navigate('/dashboard'); // Navigate after successful Google sign-in
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="signin-container">
      <form onSubmit={signIn} className="signin-form">
        <h2 className="form-title">Войти</h2> {/* Translated */}
        <div className="form-group">
          <label htmlFor="email">Электронная почта</label> {/* Translated */}
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Введите вашу электронную почту" // Translated
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Пароль</label> {/* Translated */}
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Введите ваш пароль" // Translated
          />
        </div>
        <button type="submit" className="signin-button">Войти</button> {/* Translated */}
        <button onClick={signInWithGoogle} className="signin-button google-signin">
          <FontAwesomeIcon icon={faGoogle} />
          Войти через Google
        </button>
      </form>
    </div>
  );
};

export default SignInForm;
