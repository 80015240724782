import React, { useEffect, useState, useContext } from 'react';
import MyTaxCalendar from '../components/MyTaxCalendar';
import TaxToDoList from '../components/TaxToDoList';
import TaxSelectionForm from '../components/TaxSelectionForm'; // Ensure this is the correct path
import { fetchUserTaxSelectionsFromFirestore, fetchUserTasksFromFirestore, saveUserTasks, fetchTasksForSelectedTaxes, removeTaskFromFirestore } from '../utils/firestore';
import { useAuth } from '../context/AuthContext';
import { TaxesContext } from '../context/TaxesContext'; // Import TaxesContext
import '../css/DashBoard.css'; // Ensure this is the correct path
import DOMPurify from 'dompurify';

const DashboardPage = () => {
    const { currentUser } = useAuth();
    const [userTasks, setUserTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEvents, setSelectedEvents] = useState([]); // Changed to handle multiple events
    const { taxSelections, setTaxSelections } = useContext(TaxesContext);

    useEffect(() => {
        const fetchData = async () => {
            if (!currentUser) {
                setLoading(false);
                return;
            }
            try {
                const selections = await fetchUserTaxSelectionsFromFirestore(currentUser.uid);
                setTaxSelections(selections || {});
                const tasks = await fetchUserTasksFromFirestore(currentUser.uid);
                setUserTasks(tasks);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [currentUser, setTaxSelections]);


    if (loading) {
        return <div>Загрузка...</div>;
    }

    const hasTaxSelections = Object.keys(taxSelections).some(key => taxSelections[key]);
    const hasTasks = userTasks.length > 0;

     // Call setTaxSelections when the tax selection form is submitted and saved

     // Ensure `selectedEvent` is set with a `start` property which is a Date object
     const handleEventSelect = (events) => { // Updated to accept an array of events
        setSelectedEvents(events.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
            title: event.title.split(' - ')[0],
            description: event.title.split(' - ')[1] || ""
        })));
    };
    
    // In DashboardPage component
    const updateTasksBasedOnSelectedTaxes = async (selectedTaxes) => {
    if (!currentUser) {
        console.log("No user signed in!");
        return;
    }
    
    const tasksForSelectedTaxes = await fetchTasksForSelectedTaxes(Object.keys(selectedTaxes).filter(tax => selectedTaxes[tax]));
  
    await saveUserTasks(currentUser.uid, tasksForSelectedTaxes);
  
    // Fetch updated tasks to refresh the list
    const updatedTasks = await fetchUserTasksFromFirestore(currentUser.uid);
    setUserTasks(updatedTasks); // Update state with the new list of tasks
    };

    if (!hasTaxSelections && !hasTasks) {
        return (
            <div>
                <h1>Добро пожаловать в вашу панель управления</h1>
                <TaxSelectionForm onSubmit={updateTasksBasedOnSelectedTaxes} />
            </div>
        );
    }

    const removeTask = async (taskId) => {
        // Logic to remove the task from Firestore based on taskId
        await removeTaskFromFirestore(taskId); // Implement this Firestore utility function
    
        // Update the local state to reflect the removal
        const updatedTasks = userTasks.filter(task => task.id !== taskId);
        setUserTasks(updatedTasks);
    };

    // Allow all attributes by passing a configuration object to sanitize()
    const createMarkup = (description) => {
        // Configuration object for DOMPurify
        const config = {
          ADD_ATTR: ['target', 'href', 'rel'], // Allow these attributes
          ADD_TAGS: ['a'] // If you need to explicitly allow certain tags
        };
        
        const cleanHTML = DOMPurify.sanitize(description, config);
        return { __html: cleanHTML };
      };

    // Log selectedEvent.description to the console
    if (selectedEvents) {
        console.log(selectedEvents.description);
        console.log(createMarkup(selectedEvents.description));
        console.log('Component rendering', { selectedEvents });
    }

    return (
        <div>
            <h1>Панель управления</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', minHeight: 'calc(100vh - 60px)' }}>
                {/* Tax Selection Form */}
                <div style={{ minWidth: '250px', maxWidth: '250px', padding: '1rem', flex: '1' }}>
                    <TaxSelectionForm onSubmit={updateTasksBasedOnSelectedTaxes} />
                </div>
                
                {/* Calendar and Event Display */}
                <main style={{ flex: '2', padding: '1rem', overflowX: 'hidden', maxWidth: 'calc(50% - 2rem)' }}>
                    <div style={{ minHeight: '650px' }}>
                        {taxSelections && (
                            <MyTaxCalendar taxSelections={taxSelections} onEventSelect={handleEventSelect} />
                        )}
                        
                        {selectedEvents.length > 0 && selectedEvents.map((event, index) => (
                            <div key={index} style={{ marginTop: '20px', background: '#f5f5f5', padding: '20px', borderRadius: '5px' }}>
                                <div style={{ marginBottom: '10px', fontSize: '24px', fontWeight: 'bold' }}>
                                    {event.title}
                                </div>
                                <div style={{ fontWeight: 'bold' }}>
                                    <span style={{ fontSize: '30px', color: '#ffa500' }}>
                                        {event.start.getDate()}
                                    </span>
                                    <span style={{ fontSize: '18px', marginLeft: '5px', marginRight: '5px' }}>
                                        {`${event.start.toLocaleString('ru-RU', { month: 'long' }).replace(/\sг\./, '').replace(/\s/, '')} ${event.start.getFullYear()}`}
                                    </span>
                                </div>
                                <div style={{ marginTop: '15px', fontSize: '16px' }} dangerouslySetInnerHTML={createMarkup(event.description)}></div>
                            </div>
                        ))}
                    </div>
                </main>
        
                {/* To-Do List */}
                <div style={{ minWidth: '250px', maxWidth: '250px', padding: '1rem', flex: '1' }}>
                    {userTasks.length > 0 && <TaxToDoList tasks={userTasks} onRemoveTask={removeTask} />}
                </div>
            </div>
        </div>    
    );
    
    
};

export default DashboardPage;