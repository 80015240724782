import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { getFirestore, collection, getDocs, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function TaxListView() {
    const [taxes, setTaxes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTaxes = async () => {
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, 'taxDueDates'));
            const fetchedTaxes = [];
            querySnapshot.forEach((docSnapshot) => {
                fetchedTaxes.push({
                    taxName: docSnapshot.id,
                    dueDates: docSnapshot.data().dueDates,
                });
            });
            setTaxes(fetchedTaxes);
        };

        fetchTaxes();
    }, []);

    const handleEdit = (taxName, dueDateIndex) => {
        navigate(`/admin/taxes/edit/${encodeURIComponent(taxName)}/${dueDateIndex}`);
    };

    const handleDelete = async (taxName, dueDateIndex) => {
        const db = getFirestore();
        const taxRef = doc(db, "taxDueDates", taxName);
        const taxSnap = await getDoc(taxRef);
    
        if (taxSnap.exists()) {
            let dueDates = taxSnap.data().dueDates;
            dueDates.splice(dueDateIndex, 1); // Remove the selected due date
    
            if (dueDates.length === 0) {
                // If the dueDates array is empty after deletion, delete the entire document
                await deleteDoc(taxRef);
            } else {
                // Otherwise, just update the document with the remaining due dates
                await updateDoc(taxRef, { dueDates });
            }
    
            // Refresh the list after deletion or document removal
            window.location.reload(); // Consider fetching the taxes again instead of reloading the page for a smoother UX
        }
    };    

    const handleAddNewTax = () => {
        navigate('/admin/taxes/new');
    };

    return (
        <div>
            <Button onClick={handleAddNewTax} style={{ margin: '20px' }}>Add New Tax</Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tax Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {taxes.map((tax) => 
                            tax.dueDates.map((dueDate, index) => (
                                <TableRow key={`${tax.taxName}-${index}`}>
                                    {index === 0 && <TableCell rowSpan={tax.dueDates.length}>{tax.taxName}</TableCell>}
                                    <TableCell>{dueDate.date}</TableCell>
                                    <TableCell>{dueDate.description}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEdit(tax.taxName, index)}>Edit</Button>
                                        <Button onClick={() => handleDelete(tax.taxName, index)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TaxListView;
