// ../src/context/TaxesContext.js

import React, { createContext, useState, useEffect } from 'react';

export const TaxesContext = createContext();

export const TaxesProvider = ({ children }) => {
  const [taxSelections, setTaxSelections] = useState(() => {
    // Load saved tax selections from local storage if available
    const savedSelections = localStorage.getItem('taxSelections');
    return savedSelections ? JSON.parse(savedSelections) : {};
  });

  // This effect runs when taxSelections state changes
  useEffect(() => {
    // Save tax selections to local storage
    localStorage.setItem('taxSelections', JSON.stringify(taxSelections));
  }, [taxSelections]);

  return (
    <TaxesContext.Provider value={{ taxSelections, setTaxSelections }}>
      {children}
    </TaxesContext.Provider>
  );
};
