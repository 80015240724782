import React, { useState, useEffect } from 'react';
import '../css/TaxToDoList.css';
import { taxColorMapping } from './colorMapping';
import DOMPurify from 'dompurify';

const TaxToDoList = ({ tasks, onRemoveTask, onToggleComplete }) => {
    const [tasksData, setTasksData] = useState(tasks);

    useEffect(() => {
        setTasksData(tasks);
    }, [tasks]);

    const formatDate = (dateInput) => {
        if (!dateInput) return 'Дата не указана';

        let date;
        if (dateInput.toDate) {
            date = dateInput.toDate();
        } else if (dateInput instanceof Date) {
            date = dateInput;
        } else {
            date = new Date(dateInput);
        }

        if (!isNaN(date.getTime())) {
            return date.toLocaleDateString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric' });
        }

        return 'Неверная дата';
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for comparison

    const handleToggleTask = (taskId) => {
        const updatedTasks = tasksData.map(task => {
            if (task.id === taskId) {
                return { ...task, completed: !task.completed };
            }
            return task;
        });
        setTasksData(updatedTasks);
        if (onToggleComplete) {
            onToggleComplete(taskId);
        }
    };

    const handleDeleteTask = (taskId) => {
        const updatedTasks = tasksData.filter(task => task.id !== taskId);
        setTasksData(updatedTasks);
        if (onRemoveTask) {
            onRemoveTask(taskId);
        }
    };

    const renderTasks = (tasks, category) => {
        return tasks.length > 0 ? (
            <ul>
                {tasks.map(task => (
                    <li key={task.id} className={`todo-item ${task.completed ? 'completed' : ''}`} style={{ borderLeft: `4px solid ${taxColorMapping[task.taxName] || '#ddd'}`}}>
                        <div className="todo-item-details">
                            <div className="todo-item-date">Срок оплаты: {formatDate(task.date)}</div>
                            <div className="todo-item-name">{task.taxName}</div>
                            <div className="todo-item-description" dangerouslySetInnerHTML={createMarkup(`Описание: ${task.description}`)}></div>
                            {category !== 'completed' ? (
                                <input className="todo-input" type="checkbox" checked={task.completed} onChange={() => handleToggleTask(task.id)} />
                            ) : (
                                <>
                                    <button className="return-btn" onClick={() => handleToggleTask(task.id)}>Вернуть</button>
                                    <button className="delete-btn" onClick={() => handleDeleteTask(task.id)}>Удалить</button>
                                </>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        ) : <p>Нет задач для отображения.</p>;
    };
    

    const tasksCategorized = {
        today: tasksData.filter(task => !task.completed && new Date(task.date).toDateString() === today.toDateString()),
        upcoming: tasksData.filter(task => !task.completed && new Date(task.date) > today),
        completed: tasksData.filter(task => task.completed)
    };

    return (
        <div className="todo-list-container">
            <h2>Мой список задач:</h2>
            <div>
                <h3>Сегодня</h3>
                {renderTasks(tasksCategorized.today, 'today')}
                <h3>Предстоящие</h3>
                {renderTasks(tasksCategorized.upcoming, 'upcoming')}
                <h3>Выполнено</h3>
                {renderTasks(tasksCategorized.completed, 'completed')}
            </div>
        </div>
    );

    function createMarkup(htmlString) {
        return { __html: DOMPurify.sanitize(htmlString) };
    }
};

export default TaxToDoList;
