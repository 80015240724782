// src/components/SignUpForm.js
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../css/SignInForm.css'; // Используется тот же стиль
import { createUserProfile } from '../utils/firestore'; // Убедитесь, что это правильно импортировано
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const SignUpForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = getAuth();
    const navigate = useNavigate(); // Правильное использование хука useNavigate

    const signUp = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            console.log("Учетная запись успешно создана!");

            // Создать или обновить профиль пользователя
            await createUserProfile(userCredential.user);

            navigate('/dashboard'); // Перейти после успешного входа
        } catch (error) {
            console.error("Ошибка регистрации:", error.message);
        }
    };

    const signUpWithGoogle = async () => {
        const provider = new GoogleAuthProvider(); // Определите экземпляр провайдера
        try {
            const result = await signInWithPopup(auth, provider);
            console.log("Учетная запись создана через Google!");

            // Создать или обновить профиль пользователя
            await createUserProfile(result.user);

            navigate('/dashboard'); // Перейти после успешного входа
        } catch (error) {
            console.error("Ошибка регистрации через Google:", error.message);
        }
    };

    return (
        <div className="signin-container">
            <form onSubmit={signUp} className="signin-form">
                <h2 className="form-title">Регистрация</h2>
                <div className="form-group">
                    <label htmlFor="email">Электронная почта</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Введите вашу электронную почту"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Пароль</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Создайте пароль"
                    />
                </div>
                <button type="submit" className="signin-button">Зарегистрироваться</button>
                {/* Предотвратить действие отправки формы по умолчанию на кнопке входа через Google */}
                <button onClick={signUpWithGoogle} className="signin-button google-signin">
                <FontAwesomeIcon icon={faGoogle} />
                Регистрация через Google                
                </button>
            </form>
        </div>
    );
};

export default SignUpForm;