// ../src/pages/HomePage.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../css/HomePage.css'; // Ensure you create this CSS file for styling

const HomePage = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Добро пожаловать в «Налоговый календарь» от iConsultans</h1>
        <p>Ваше решение для упрощения налогового планирования и управления.</p>
      </header>
      <section className="home-features">
        <div className="feature">
          <h2>Организуйте свои налоги</h2>
          <p>Выберите налоги, которые вы платите, и мы автоматически создадим для вас календарь сроков платежей.</p>
        </div>
        <div className="feature">
          <h2>Следите за задачами</h2>
          <p>Получите персонализированный список задач, чтобы ничего не упустить.</p>
        </div>
        <div className="feature">
          <h2>Получите напоминания</h2>
          <p>Настройте уведомления, чтобы всегда быть в курсе предстоящих налоговых обязательств.</p>
        </div>
      </section>
      <section className="home-cta">
        <Link to="/signup" className="cta-signup">Зарегистрируйтесь сейчас</Link>
        <Link to="/signin" className="cta-signin">Уже есть аккаунт? Войти</Link>
      </section>
    </div>
  );
};

export default HomePage;
