// ../src/AppRouter.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBRwOk0es3DYpnntI6zbt7dDP69sGjyKZA",
  authDomain: "iconscalendar-2024.firebaseapp.com",
  projectId: "iconscalendar-2024",
  storageBucket: "iconscalendar-2024.appspot.com",
  messagingSenderId: "816221405239",
  appId: "1:816221405239:web:bbfa15e2d014fc86a3ffb2",
  measurementId: "G-HJPZ9DPN36"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export auth
export const auth = getAuth(app);
