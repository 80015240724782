import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from "firebase/firestore";

function NavBar() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const auth = getAuth();
    const [userRole, setUserRole] = useState(null);
    const [isOpen, setIsOpen] = useState(false); // State to handle mobile menu toggle

    useEffect(() => {
        const fetchUserRole = async () => {
            if (currentUser) {
                const db = getFirestore();
                const userRef = doc(db, "users", currentUser.uid);
                const docSnap = await getDoc(userRef);
                if (docSnap.exists() && docSnap.data().role) {
                    setUserRole(docSnap.data().role);
                }
            }
        };
        fetchUserRole();
    }, [currentUser]);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
            localStorage.removeItem('taxSelections');
        } catch (error) {
            console.error("Logout Error:", error);
        }
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen); // Toggle the state to show/hide the menu
    };

    return (
        <nav className="App-header">
            <button className="menu-button" onClick={toggleMenu}>☰</button>
            <div className={isOpen ? "nav-links open" : "nav-links"}>
                <Link to="/" className="nav-link" onClick={() => setIsOpen(false)}>Главная</Link>
                {!currentUser && (
                    <>
                        <Link to="/signin" className="nav-link" onClick={() => setIsOpen(false)}>Войти</Link>
                        <Link to="/signup" className="nav-link" onClick={() => setIsOpen(false)}>Регистрация</Link>
                    </>
                )}
                {currentUser && (
                    <>
                        <Link to="/dashboard" className="nav-link" onClick={() => setIsOpen(false)}>Панель</Link>
                        {userRole === 'admin' && <Link to="/admin" className="nav-link" onClick={() => setIsOpen(false)}>Админ</Link>}
                        <button onClick={handleLogout} className="nav-link" style={{ border: 'none', background: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer', outline: 'inherit' }}>Выйти</button>
                    </>
                )}
            </div>
        </nav>
    );
}

export default NavBar;
