//src/components/MyTaxCalendar.js

import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import 'moment/locale/ru';
import { taxColorMapping } from './colorMapping';
import { fetchAllTaxDueDates } from '../utils/firestore';
import '../css/Calendar.css';

moment.locale('ru');

const MyTaxCalendar = ({ taxSelections, onEventSelect }) => {
    const [events, setEvents] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date()); // Current month state

    const lastMonthDate = moment(currentMonth).subtract(1, 'month').toDate();
    const nextMonthDate = moment(currentMonth).add(1, 'month').toDate();
    
    useEffect(() => {
        const fetchAndSetEvents = async () => {
            const allTaxDueDates = await fetchAllTaxDueDates();
            const filteredTaxDueDates = Object.keys(allTaxDueDates)
                .filter(taxName => taxSelections[taxName])
                .reduce((obj, key) => {
                    obj[key] = allTaxDueDates[key];
                    return obj;
                }, {});
            const events = convertTaxDueDatesToEvents(filteredTaxDueDates);
            setEvents(events);
        };

        if (taxSelections) {
            fetchAndSetEvents();
        }
    }, [taxSelections]);


// Use this function to update the month when the user navigates through the calendar
const onActiveDateChange = ({ activeStartDate, view }) => {
    console.log('Navigating to a new date:', activeStartDate, 'View:', view);
    if (view === 'month') {
        setCurrentMonth(activeStartDate);
    }
};


const handleDayClick = (date) => {
    const dayEvents = events.filter(event => moment(event.start).isSame(date, 'day'));
    if (dayEvents.length > 0) {
        onEventSelect(dayEvents); // Now passing an array of events
    }
};


const tileContent = ({ date, view }) => {
    if (view === 'month') {
        const dayEvents = events.filter(event => moment(event.start).isSame(date, 'day'));
        if (dayEvents.length > 0) {
            // Render a blue square if there are events on this day
            return (
                <div className="event-indicator" style={{ backgroundColor: '#007bff' }}></div>
            );
        }
    }
};
console.log(currentMonth)

return (
    <div className="calendar-container">
        <Calendar
            className="calendar-past"
            onClickDay={handleDayClick}
            tileContent={tileContent}
            value={null}
            locale="ru-RU"
            view="month"
            activeStartDate={lastMonthDate}
            onActiveDateChange={onActiveDateChange}
        />
        <Calendar
            className="calendar-current"
            onClickDay={handleDayClick}
            tileContent={tileContent}
            value={new Date()} // Always show the current date as selected in the current month
            locale="ru-RU"
            view="month"
            activeStartDate={currentMonth}
            onActiveDateChange={onActiveDateChange}
        />
        <Calendar
            className="calendar-next"
            onClickDay={handleDayClick}
            tileContent={tileContent}
            value={null}
            locale="ru-RU"
            view="month"
            activeStartDate={nextMonthDate}
            onActiveDateChange={onActiveDateChange}
        />
    </div>
);

};

export default MyTaxCalendar;

const convertTaxDueDatesToEvents = (taxDueDates) => {
    let events = [];
    Object.entries(taxDueDates).forEach(([taxType, dueDates]) => {
        dueDates.forEach(({ date, description }) => {
            if (!description) {
                console.error(`Отсутствует описание для типа налога: ${taxType}, дата: ${date}`);
            }
            const eventDate = moment(date, 'YYYY-MM-DD').toDate();
            events.push({
                title: `${taxType} - ${description}`,
                start: eventDate,
                color: taxColorMapping[taxType] || '#3174ad',
            });
        });
    });
    return events;
};
