import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { updateTasksForTaxChange } from '../utils/firestore'; // Ensure this is correctly imported
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import quill styles


// Adding some basic styling
const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '20px',
    maxWidth: '500px',
    margin: 'auto',
  },
  input: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  textarea: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minHeight: '80px',
  },
  button: {
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    marginTop: '10px',
  },
  removeButton: {
    backgroundColor: '#dc3545',
  }
};

const TaxForm = () => {
  const { taxName: initialTaxName } = useParams();
  const [taxName, setTaxName] = useState('');
  const [dueDates, setDueDates] = useState([{ date: new Date(), description: '' }]);
  const navigate = useNavigate();
  // Use a new state for the rich text editor content

  useEffect(() => {
    const fetchTaxData = async () => {
      if (initialTaxName) {
        const db = getFirestore();
        const docRef = doc(db, "taxDueDates", initialTaxName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setTaxName(initialTaxName);
          setDueDates(docSnap.data().dueDates.map(dueDate => ({
            date: new Date(dueDate.date),
            description: dueDate.description
          })));
        }
      }
    };

    fetchTaxData();
  }, [initialTaxName]);

  const handleDueDateChange = (index, field, value) => {
    const newDueDates = [...dueDates];
    newDueDates[index][field] = value;
    setDueDates(newDueDates);
  };

  const handleDescriptionChange = (value, index) => {
    const newDueDates = [...dueDates];
    newDueDates[index].description = value;
    setDueDates(newDueDates);
  };

  const handleAddDueDate = () => {
    setDueDates([...dueDates, { date: new Date(), description: '' }]);
  };

  const handleRemoveDueDate = (index) => {
    const newDueDates = [...dueDates];
    newDueDates.splice(index, 1);
    setDueDates(newDueDates);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const db = getFirestore();
    const docRef = doc(db, "taxDueDates", taxName || initialTaxName);

    await setDoc(docRef, {
      dueDates: dueDates.map(dueDate => ({
        date: dueDate.date.toISOString().split('T')[0],
        description: dueDate.description
      }))
    }, { merge: true });

    // Assuming updateTasksForTaxChange is a utility function you will implement
    await updateTasksForTaxChange(taxName, dueDates);

    navigate('/admin/taxes');
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div>
        <label>Tax Name</label>
        <input
          style={styles.input}
          type="text"
          value={taxName}
          onChange={(e) => setTaxName(e.target.value)}
          disabled={!!initialTaxName}
        />
      </div>
      {dueDates.map((dueDate, index) => (
        <div key={index}>
          <label>Due Date {index + 1}</label>
          <DatePicker
            selected={dueDate.date}
            onChange={(date) => handleDueDateChange(index, 'date', date)}
          />
          <label>Description</label>
          <ReactQuill
            value={dueDate.description}
            onChange={(value) => handleDescriptionChange(value, index)}
            theme="snow"
            modules={TaxForm.modules}
            formats={TaxForm.formats}
          />
          {dueDates.length > 1 && (
            <button type="button" onClick={() => handleRemoveDueDate(index)} style={{...styles.button, ...styles.removeButton}}>Remove</button>
          )}
        </div>
      ))}
      
      <button type="button" onClick={handleAddDueDate} style={styles.button}>Add Another Due Date</button>
      <button type="submit" style={styles.button}>Save</button>
    </form>
  );
};

// Add the Quill modules to customize the toolbar options
TaxForm.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

TaxForm.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default TaxForm;
