import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemText, Toolbar, AppBar, Typography } from '@mui/material';
import TaxForm from './TaxForm';
import TaxListView from './TaxListView';

const drawerWidth = 240;
const mainNavbarHeight = 95; // Adjust this value based on your main navbar's height

function AdminLayout() {
  const navigate = useNavigate();

  const adminSections = [
    { text: 'Taxes', path: '/admin/taxes' },
    // Add more sections as needed
  ];

  return (
    <Box sx={{ display: 'flex', pt: `${mainNavbarHeight}px` }}> {/* Add padding top to the Box */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, top: mainNavbarHeight }}> {/* Move AppBar down */}
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', top: mainNavbarHeight }, // Move Drawer down
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {adminSections.map(({ text, path }) => (
              <ListItem button key={text} onClick={() => navigate(path)}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: `calc(100% - ${drawerWidth}px)` }} // Ensure the main content box takes up the remaining space
      >
        <Toolbar /> {/* This Toolbar is for spacing; it matches the AppBar's height */}
        <Routes>
            <Route path="taxes" element={<TaxListView />} />
            <Route path="taxes/new" element={<TaxForm />} />
            <Route path="taxes/edit/:taxId" element={<TaxForm />} />
            {/* Define other admin child routes as needed */}
        </Routes>
      </Box>
    </Box>
  );
}

export default AdminLayout;
