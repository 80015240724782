// src/utils/firestore.js

import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs, addDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Function to save tax selections to Firestore
export async function saveTaxSelectionsToFirestore(uid, taxSelections) {
    const db = getFirestore();
    const userTaxSelectionsDocRef = doc(db, "userTaxSelections", uid);

    try {
        // Assuming taxSelections is now an array of selected tax types
        await setDoc(userTaxSelectionsDocRef, { taxSelections });
        console.log("Tax selections saved successfully!");
    } catch (error) {
        console.error("Error saving tax selections:", error);
    }
}


// Function to fetch tax selections for the current user from Firestore
export async function fetchUserTaxSelectionsFromFirestore() {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const uid = user.uid;
    const userTaxSelectionsDocRef = doc(db, "userTaxSelections", uid);

    try {
      const docSnap = await getDoc(userTaxSelectionsDocRef);
      if (docSnap.exists()) {
        console.log("Tax selections fetched successfully!");
        return docSnap.data().taxSelections;
      } else {
        console.log("No tax selections found!");
        return {}; // or return a default value that your app expects
      }
    } catch (error) {
      console.error("Error fetching tax selections:", error);
      return {}; // or return a default value that your app expects
    }
  } else {
    console.log("No user signed in!");
    return {}; // or return a default value that your app expects
  }
}

// Function to fetch user-specific tasks from Firestore
    export async function fetchUserTasksFromFirestore(uid) {
    const db = getFirestore();
    const tasksCollectionRef = collection(db, "userTasks");
    const q = query(tasksCollectionRef, where("userId", "==", uid));

    try {
        const querySnapshot = await getDocs(q);
        const tasks = [];
        querySnapshot.forEach((doc) => {
            tasks.push({ id: doc.id, ...doc.data() });
        });
        console.log("Tasks fetched successfully!");
        return tasks;
    } catch (error) {
        console.error("Error fetching tasks:", error);
        return []; // Return an empty array or handle the error as needed
    }
}


// Function to fetch all tax due dates from Firestore
export const fetchAllTaxDueDates = async () => {
    const db = getFirestore();
    const taxDueDatesCollectionRef = collection(db, "taxDueDates");
    const querySnapshot = await getDocs(taxDueDatesCollectionRef);
    const allTaxDueDates = {};
    querySnapshot.forEach((doc) => {
        // Assuming each document's ID is the tax name
        const taxName = doc.id;
        const dueDates = doc.data().dueDates.map(dueDate => ({
            ...dueDate,
            // Convert date string to Date object, adjust if using Timestamps
            date: new Date(dueDate.date)
        }));
        allTaxDueDates[taxName] = dueDates;
    });
    return allTaxDueDates;
};

export async function fetchTasksForSelectedTaxes(selectedTaxes) {
  const db = getFirestore();
  const tasksForSelectedTaxes = [];

  for (const tax of selectedTaxes) {
    const taxDocRef = doc(db, "taxDueDates", tax);
    const taxDocSnap = await getDoc(taxDocRef);

    if (taxDocSnap.exists()) {
      const dueDates = taxDocSnap.data().dueDates;
      tasksForSelectedTaxes.push(...dueDates.map(dueDate => {
        // Convert string date to Date object
        const date = new Date(dueDate.date);
        return {
          taxName: tax,
          date: date, // This is now a Date object
          description: dueDate.description,
        };
      }));
    }
  }

  return tasksForSelectedTaxes;
}


let tasksBeingAdded = new Set();

export async function saveUserTasks(uid, tasksForSelectedTaxes) {
  const db = getFirestore();

  // Fetch current tasks to check for duplicates
  const existingTasks = await fetchUserTasksFromFirestore(uid);
  const existingTasksIdentifiers = new Set(existingTasks.map(task => {
    // Check if task.date is a valid Date object or convertible to one
    let date;
    if (task.date && task.date.toDate && typeof task.date.toDate === 'function') {
      // Handling Firestore Timestamps
      date = task.date.toDate();
    } else if (typeof task.date === 'string' || task.date instanceof Date) {
      // Handling ISO string or Date object
      date = new Date(task.date);
    }
    if (!(date instanceof Date && !isNaN(date))) {
      console.error("Invalid date encountered for existing task:", task);
      return null; // Skip this task as it has an invalid date
    }
    return `${task.taxName}-${date.toISOString()}`;
  }).filter(identifier => identifier !== null)); // Remove any null identifiers due to invalid dates

  for (const task of tasksForSelectedTaxes) {
    // Handling the date conversion and check for validity
    const date = new Date(task.date);
    if (!(date instanceof Date && !isNaN(date))) {
      console.error("Invalid date encountered for new task:", task);
      continue; // Skip to the next task if this one has an invalid date
    }
    const taskIdentifier = `${task.taxName}-${date.toISOString()}`;

    // Check against tasksBeingAdded to prevent duplicate additions
    if (!existingTasksIdentifiers.has(taskIdentifier) && !tasksBeingAdded.has(taskIdentifier)) {
      tasksBeingAdded.add(taskIdentifier);
      const taskRef = collection(db, "userTasks");
      await addDoc(taskRef, {
        userId: uid,
        taxName: task.taxName,
        date: date, // Using the validated and converted Date object
        description: task.description
      });
      tasksBeingAdded.delete(taskIdentifier); // Once added, remove from tasksBeingAdded
    }
  }
}

export async function removeTaskFromFirestore(taskId) {
  const db = getFirestore();
  await deleteDoc(doc(db, "userTasks", taskId)); // Corrected syntax for deleting a document
}


// Function to create or update user profile
export async function createUserProfile(userAuthData, additionalData = {}) {
  const db = getFirestore();
  const userRef = doc(db, "users", userAuthData.uid);

  const userSnap = await getDoc(userRef);
  if (!userSnap.exists()) {
      // User profile doesn't exist, create a new one
      try {
          await setDoc(userRef, {
              email: userAuthData.email,
              role: "user", // Default role
              ...additionalData,
          });
          console.log("User profile created!");
      } catch (error) {
          console.error("Error creating user profile:", error);
      }
  }
}

// Utility function to get users who selected a specific tax
export async function getUsersWhoSelectedTax(taxName) {
  const db = getFirestore();
  const usersRef = collection(db, "userTaxSelections");
  const q = query(usersRef, where(`taxSelections.${taxName}`, "==", true));

  const users = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    users.push(doc.id); // Assuming doc.id is the user's UID
  });

  return users;
}

// Ensure to include getUsersWhoSelectedTax function as described above

export async function updateTasksForTaxChange(taxName, dueDates) {
  const db = getFirestore();
  const users = await getUsersWhoSelectedTax(taxName);

  for (const userId of users) {
    const userTasksRef = collection(db, "userTasks");
    const q = query(userTasksRef, where("userId", "==", userId), where("taxName", "==", taxName));
    const existingTasksSnapshot = await getDocs(q);
    
    const existingTasks = existingTasksSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    for (const dueDate of dueDates) {
      const dateString = dueDate.date.toISOString().split('T')[0];
      const taskExists = existingTasks.some(task => task.date === dateString);

      if (!taskExists) {
        // Task for this due date does not exist, create a new one
        await addDoc(userTasksRef, {
          userId,
          taxName,
          date: dateString,
          description: dueDate.description
        });
      } else {
        // If task exists, consider updating it if necessary
        const existingTask = existingTasks.find(task => task.date === dateString);
        await updateDoc(doc(db, "userTasks", existingTask.id), {
          description: dueDate.description // Only updating description, add more fields if needed
        });
      }
    }
  }
}