// ../src/components/TaxSelectionForm.js

import React, { useState, useContext, useEffect } from 'react';
import '../css/TaxSelectionForm.css';
import { taxColorMapping } from './colorMapping';
import { saveTaxSelectionsToFirestore, fetchTasksForSelectedTaxes, saveUserTasks } from '../utils/firestore'; // Ensure these functions are exported from your utils/firestore.js
import { useAuth } from '../context/AuthContext';
import { TaxesContext } from '../context/TaxesContext';

const taxTypes = [
  'НДС',
  'НДС по товарам (услугам), приобретенным от иностранных лиц',
  'Акцизный налог',
  'Налог на прибыль',
  'Налог на прибыль (с доходов неризидентов)',
  'Налог на доходы физических лиц (НДФЛ) и социальный налог (СН)',
  'ИНПС',
  'Налог на имущество',
  'Земельный налог по землям несельск.назн.',
  'Налог за пользование водными ресурсами',
  'Налог за пользование недрами',
  'Специальный рентный налог на добычу полезных ископаемых',
  'Налоги с дивидендов и процентов (ННП + НДФЛ)',
  // Add other tax types as needed.
];

const TaxSelectionForm = ({ onSubmit }) => { // Now `onSubmit` is accepted as a prop
  const { currentUser } = useAuth(); // Use AuthContext to get the current user.
  const { taxSelections, setTaxSelections } = useContext(TaxesContext);

  // Initialize formState with selections from context
  const [formState, setFormState] = useState(() => 
      taxTypes.reduce((state, tax) => ({
          ...state,
          [tax]: taxSelections[tax] || false // Use selections from context, default to false
      }), {})
  );

  useEffect(() => {
    // Update formState if taxSelections context changes
    setFormState(taxTypes.reduce((state, tax) => ({
        ...state,
        [tax]: taxSelections[tax] || false
    }), {}));
  }, [taxSelections]); // Re-run this effect if taxSelections changes

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState(prevState => ({ ...prevState, [name]: checked }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!currentUser) {
        console.log("No user signed in!");
        return;
    }

    await saveTaxSelectionsToFirestore(currentUser.uid, formState);
    setTaxSelections(formState); // Update the context with the new selections.

    // Add this line to notify the parent component to update tasks
    onSubmit && onSubmit(formState);

    // Optionally, generate tasks based on the selected taxes and save them for the user.
    const selectedTaxes = Object.keys(formState).filter(tax => formState[tax]);
    const tasksForSelectedTaxes = await fetchTasksForSelectedTaxes(selectedTaxes);
    await saveUserTasks(currentUser.uid, tasksForSelectedTaxes);
    
    // Since tasks are being generated and saved, you might want to trigger a refresh of tasks in the UI here.
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <h2>Выберите налоги, которые вы платите:</h2>
      <div className="tax-selection-form">
        {taxTypes.map((taxType, index) => (
          <div key={index} className="tax-checkbox" style={{ borderLeft: `10px solid ${taxColorMapping[taxType] || '#333'}` }}>
            <label>
              <input
                type="checkbox"
                name={taxType}
                checked={!!formState[taxType]}
                onChange={handleCheckboxChange}
              />
              {taxType}
            </label>
          </div>
        ))}
      </div>
      <div className="button-container">
        <button type="submit" className="save-selection-button">Сохранить выбор</button>
      </div>
    </form>
  );
};

export default TaxSelectionForm;