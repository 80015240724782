// ../src/App.js
import React from 'react';
import { TaxesProvider } from './context/TaxesContext';
import AppRouter from './AppRouter'; // Adjust the import path if necessary
import NavBar from './components/Navbar'; // Ensure this path is correct
import './App.css';

function App() {
  return (
    <TaxesProvider>
      <div className="App">
      <header className="App-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
        <h1>Налоговый Календарь</h1>
        <NavBar />
      </header>
      <main>
        <AppRouter />
      </main>
    </div>
    </TaxesProvider>
  );
}

export default App;
