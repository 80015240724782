// ../src/components/ViewSwitcher.js

import React, { useContext, useState } from 'react';
import MyTaxCalendar from './MyTaxCalendar';
import TaxToDoList from './TaxToDoList';
import { TaxesContext } from '../context/TaxesContext'; // Adjust path as necessary
import { getTaxCalendarEvents } from '../components/TaxDates'; // Adjust path as necessary

const buttonStyle = {
    padding: '10px 20px',
    margin: '0 10px',
    backgroundColor: '#007bff', // Bootstrap primary blue
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    outline: 'none',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  };
  
const ViewSwitcher = () => {
    const [view, setView] = useState('calendar');
    // Inside ViewSwitcher component
    const { taxSelections } = useContext(TaxesContext);
    const tasks = getTaxCalendarEvents(taxSelections); // Convert tax selections to tasks
  
    return (
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <button onClick={() => setView('calendar')} style={buttonStyle}>Calendar View</button>
        <button onClick={() => setView('todoList')} style={buttonStyle}>To-Do List View</button>

        {view === 'todoList' ? <TaxToDoList tasks={tasks} /> : <MyTaxCalendar />}
      </div>
    );
  };
  
  export default ViewSwitcher;
