// ../src/components/colorMapping.js

export const taxColorMapping = {
  'НДС': '#0056b3', // Deep blue
  'НДС по товарам (услугам), приобретенным от иностранных лиц': '#4b0082', // Indigo
  'Акцизный налог': '#483d8b', // Dark slate blue
  'Налог на прибыль': '#c71585', // Medium violet red
  'Налог на прибыль (с доходов неризидентов)': '#b22222', // Firebrick
  'Налог на доходы физических лиц (НДФЛ) и социальный налог (СН)': '#ff8c00', // Dark orange
  'ИНПС': '#daa520', // Golden rod
  'Налог на имущество': '#2e8b57', // Sea green
  'Земельный налог по землям несельск.назн.': '#3cb371', // Medium sea green
  'Налог за пользование водными ресурсами': '#20b2aa', // Light sea green
  'Налог за пользование недрами': '#696969', // Dim grey
  'Специальный рентный налог на добычу полезных ископаемых': '#708090', // Slate grey
  'Налоги с дивидендов и процентов (ННП + НДФЛ)': '#4682b4', // Steel blue
  // New tax types with their colors
  'Налог с оборота': '#8b4513', // Saddle brown
  'Финансовая отчетность': '#006400', // Dark green
};

