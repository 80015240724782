// src/AppRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TaxSelectionForm from './components/TaxSelectionForm';
import ViewSwitcher from './components/ViewSwitcher';
import SignInForm from './components/SignInForm';
import SignUpForm from './components/SignUpForm';
import DashboardPage from './components/Dashboard';
import AdminLayout from './components/AdminLayout';
import TaxForm from './components/TaxForm';

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/tax-selection" element={<TaxSelectionForm />} />
    <Route path="/view" element={<ViewSwitcher />} />
    <Route path="/signin" element={<SignInForm />} />
    <Route path="/signup" element={<SignUpForm />} />
    <Route path="/dashboard" element={<DashboardPage />} />
    <Route path="/admin/*" element={<AdminLayout />} />
    <Route path="/admin/taxes/edit/:taxName/:dueDateIndex" element={<TaxForm />} />
  </Routes>
);

export default AppRouter;
