// ../src/components/TaxDates.js

import { taxColorMapping } from '../components/colorMapping';

const taxDueDates = {
    'НДС': [
      { dueDate: '2024-03-20', description: 'расчет / уплата за Февраль 2024' },
    ],
    'НДС по товарам (услугам), приобретенным от иностранных лиц': [
      { dueDate: '2024-03-20', description: 'расчет / уплата за Февраль 2024' },
    ],
    'Акцизный налог': [
      { dueDate: '2024-03-11', description: 'расчет / уплата за Февраль 2024' },
    ],
    'Налог на прибыль': [
      { dueDate: '2024-03-01', description: 'расчет / уплата за 2023' },
      { dueDate: '2024-03-20', description: 'уведомл. о контр. инотср. комп. За 2023' },
      { dueDate: '2024-03-25', description: 'уплата аванса (10) за февраль 2024' },
    ],
    'Налог на прибыль (с доходов неризидентов)': [
      { dueDate: '2024-03-05', description: 'уплата (1) за Февраль 2024 (банками)' },
      { dueDate: '2024-03-20', description: 'расчет (5) за Февраль 2024' },
    ],
    'Налог на доходы физических лиц (НДФЛ) и социальный налог (СН)': [
      { dueDate: '2024-03-07', description: 'Уплата НДФЛ за Февраль 2024 (с доходов в натур.форме)' },
      { dueDate: '2024-03-15', description: 'Расчет / уплата СН и НДФЛ(4) за Февраль 2024' },
    ],
    'ИНПС': [
      { dueDate: '2024-03-14', description: 'Расчет / уплата за Февраль 2024' },
    ],
    'Налог на имущество': [
      { dueDate: '2024-03-01', description: 'Расчет / уплата за 2023' },
      { dueDate: '2024-03-11', description: 'Уплата аванса (2) за Февраль 2024' },
      { dueDate: '2024-03-20', description: 'уплата аванса^6 за 1-квартал 2024' },
    ],
    'Земельный налог по землям несельск.назн.': [
      { dueDate: '2024-03-11', description: 'Уплата(3) за Февраль 2024' },
      { dueDate: '2024-03-20', description: 'уплата аванса за 1-квартал 2024' },
    ],
    'Налог за пользование водными ресурсами': [
      { dueDate: '2024-03-01', description: 'Расчет / уплата за 2023' },
      { dueDate: '2024-03-20', description: 'уплата аванса за март и 1 кв.2024' },
    ],
    'Налог за пользование недрами': [
      { dueDate: '2024-03-20', description: 'расчет / уплата за Февраль 2024' },
    ],
    'Специальный рентный налог на добычу полезных ископаемых': [
      { dueDate: '2024-03-01', description: 'Расчет / уплата за 2023' },
    ],
    'Налоги с дивидендов и процентов (ННП + НДФЛ)': [
      { dueDate: '2024-03-20', description: 'расчет(9) за Февраль 2024' },
    ],
    'Налог с оборота': [
      { dueDate: '2024-03-15', description: 'Расчет / уплата Февраль 2024' },
      { dueDate: '2024-03-15', description: 'Уведомлен. о переходе на уплата налога в фик. Сумме с Апреля' },
    ],
    'Финансовая отчетность': [
      { dueDate: '2024-03-15', description: 'Расчет за 2023 мин-ва, ведомства и т.д' },
      { dueDate: '2024-03-25', description: 'Расчет (11) за 2023 (предпр. с иностр.инв.)' },
    ],
};
    
export const getTaxCalendarEvents = (taxSelections) => {
    let events = [];
    Object.keys(taxSelections).forEach(taxType => {
        if (taxSelections[taxType]) {
            const dates = taxDueDates[taxType] || []; // Fallback to empty array if undefined
            dates.forEach(date => {
                events.push({
                    title: taxType + ' - ' + date.description,
                    start: new Date(date.dueDate),
                    end: new Date(date.dueDate),
                    allDay: true,
                    color: taxColorMapping[taxType], // Add color property to the event
                });
            });
        }
    });
    return events;
};


// Example of Adding a Document:
//Document ID: "VAT"
//Fields:
//dueDates (Array)
//Element 0 (Map)
//date (Timestamp): 20 March 2024
//description (String): "Payment for February 2024"
//Element 1 (Map)
//date (Timestamp): 20 April 2024
//description (String): "Payment for March 2024"